// src/pages/Home.tsx
import React from 'react';
import { Paper } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import Glogal from './Global';
import Instaladores from './Instaladores';
import { useAuth } from '../../context/auth';
import { AccessDenied } from '../../components/AccessDenied/Index';

const Home: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { permisos } = useAuth();
  const { Prm_Config, Prm_Config_Usr_Ver, Prm_Config_Instal_Ver, Prm_Config_Tip_Id_Ver, Prm_Config_Cltes_Ver, Prm_Config_Prod_Ver, Prm_Config_Categ_Ver, Prm_Config_Unid_Ver } = permisos;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (Prm_Config === 0) {
    return <AccessDenied />;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, height: 'calc(100% - 90px)', marginBottom: 50, overflow: 'auto' }}>
      <h4 style={{ marginTop: -10 }}>Materiales</h4>

      <Tabs value={activeTab} onChange={handleTabChange} scrollButtons='auto' variant='scrollable'>
        <Tab label='Instaladores' />
        <Tab label='Inventario global' />
      </Tabs>
      {activeTab === 0 && <Instaladores />}
      {activeTab === 1 && <Glogal />}
    </Paper>
  );
};

export default Home;
