// approutes.tsx
import { HomePage, Configuracion, Inventario, Liquidaciones, DashBoard, Creditos, Materiales } from './pages';
import { withNavigationWatcher } from './context/navigation';

const routes = [
  {
    path: '/home',
    element: DashBoard,
    name: 'Principal',    
    permission: '',
  },
  {
    path: '/servicios',
    element: HomePage,
    name: 'Servicios',    
    permission: 'Prm_Serv_Ver',
  },
  {
    path: '/inventario',
    element: Inventario,
    name: 'Inventario',
    permission: 'Prm_Invent_Ver',
  },
  {
    path: '/liquidaciones',
    element: Liquidaciones,
    name: 'Liquidaciones',
    permission: 'Prm_Liquid_Ver',
  },
  {
    path: '/Creditos',
    element: Creditos,
    name: 'Créditos',
    permission: 'Prm_Credito_Ver',
  },
  {
    path: '/Materiales',
    element: Materiales,
    name: 'Materiales',
    permission: 'Prm_Mat_Ver',
  },
  {
    path: '/Configuracion',
    element: Configuracion,
    name: 'Configuración', 
    permission: 'Prm_Config',
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
