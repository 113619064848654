import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Box } from '@mui/material';
import { createMaterial } from '../../../api/materiales';
import { UseAlert } from '../../../components/newAlert/NewAlert';

interface Instalador {
  In_Id: number;
  In_Nombre: string;
  In_Apellido: string;
}

interface Categoria {
  Mt_In_Id: number | string;
  week: {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  } | null;
}

// Función para generar las semanas
const generateWeeks = (): { weekNumber: number; startDate: Date; endDate: Date; label: string }[] => {
  const currentDate = new Date();
  const weeks = [];

  // Si hoy es domingo, avanzar al siguiente día (lunes)
  const dayOfWeek = currentDate.getDay();
  const adjustedDate = dayOfWeek === 0 ? new Date(currentDate.setDate(currentDate.getDate() + 1)) : currentDate;

  // Generar semanas desde la fecha ajustada (5 semanas atrás, 10 semanas adelante)
  for (let i = -5; i <= 10; i++) {
    const monday = new Date(adjustedDate);
    monday.setDate(adjustedDate.getDate() + i * 7 - ((adjustedDate.getDay() + 6) % 7)); // Obtener el lunes de la semana
    const saturday = new Date(monday);
    saturday.setDate(monday.getDate() + 5); // Sábado

    const weekNumber = Math.ceil(((monday.getTime() - new Date(monday.getFullYear(), 0, 1).getTime()) / 86400000 + 1) / 7);

    weeks.push({
      weekNumber,
      startDate: monday,
      endDate: saturday,
      label: `Semana ${weekNumber} - ${monday.toLocaleDateString()} - ${saturday.toLocaleDateString()}`,
    });
  }

  return weeks;
};

interface CreateCategoriaProps {
  onClose: () => void;
  instaladores: Instalador[];
}

const CreateMaterial: React.FC<CreateCategoriaProps> = React.memo(({ onClose, instaladores }) => {
  const [weeks, setWeeks] = useState<{ weekNumber: number; startDate: Date; endDate: Date; label: string }[]>([]);
  const { NewNotification } = UseAlert();
  const [categoria, setCategoria] = useState<Categoria>({
    Mt_In_Id: '',
    week: null, // Ahora almacenamos el objeto completo de la semana
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ Mt_In_Id: string; Mt_fecha_inicio: string }>({
    Mt_In_Id: '',
    Mt_fecha_inicio: '',
  });

  // Al montar el componente, generamos las semanas y seleccionamos la semana actual
  useEffect(() => {
    const generatedWeeks = generateWeeks();
    setWeeks(generatedWeeks);

    // Seleccionar la semana actual (el lunes si es domingo)
    const currentDate = new Date();
    const currentWeek = generatedWeeks.find((week) => {
      const now = currentDate.getDay() === 0 ? new Date(currentDate.setDate(currentDate.getDate() + 1)) : currentDate;
      return now >= week.startDate && now <= week.endDate;
    });

    if (currentWeek) {
      setCategoria((prev) => ({
        ...prev,
        week: currentWeek, // Almacenamos la semana actual como objeto
      }));
    }
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCategoria((prev) => ({ ...prev, [name]: value as string | number }));
  };

  const handleWeekChange = (e: any) => {
    const selectedWeek = weeks.find((week) => week.weekNumber === (e.target.value as number));
    if (selectedWeek) {
      setCategoria((prev) => ({
        ...prev,
        week: selectedWeek, // Almacenamos el objeto completo de la semana
      }));
    }
  };

  const validate = () => {
    const tempErrors = { Mt_In_Id: '', Mt_fecha_inicio: '' };

    if (!categoria.Mt_In_Id) tempErrors.Mt_In_Id = 'El instalador es requerido';
    if (!categoria.week) tempErrors.Mt_fecha_inicio = 'La semana es requerida';

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);

    // Extraemos fecha de inicio y fin del objeto de la semana seleccionada
    const payload = {
      Mt_In_Id: categoria.Mt_In_Id,
      Mt_Fecha_Inicio: categoria.week?.startDate.toISOString().split('T')[0],
      Mt_Fecha_Fin: categoria.week?.endDate.toISOString().split('T')[0],
    };
    console.log(payload);

    await createMaterial(payload)
      .then((x) => {
        if (!x.isOk) {
          NewNotification(x.message || 'Error', 'error');
          return;
        }
        NewNotification('Instalador semanal creado correctamente', 'success');
        onClose();
      })
      .finally(() => setLoading(false));
  };

  return (
    <form>
      <FormControl fullWidth size='small' error={!!errors.Mt_In_Id} sx={{ marginBottom: 3, marginTop:1 }}>
        <InputLabel>Instalador</InputLabel>
        <Select name='Mt_In_Id' value={categoria.Mt_In_Id || ''} onChange={handleChange} label='Instalador'>
          {instaladores.map((instalador) => (
            <MenuItem key={instalador.In_Id} value={instalador.In_Id}>
              {instalador.In_Nombre} {instalador.In_Apellido}
            </MenuItem>
          ))}
        </Select>
        {errors.Mt_In_Id && <span style={{ color: 'red' }}>{errors.Mt_In_Id}</span>}
      </FormControl>

      <FormControl fullWidth size='small' error={!!errors.Mt_fecha_inicio} sx={{ marginBottom: 3 }}>
        <InputLabel>Semana</InputLabel>
        <Select name='Mt_fecha_inicio' value={categoria.week?.weekNumber || ''} onChange={handleWeekChange} label='Semana'>
          {weeks.map((week, index) => (
            <MenuItem key={index} value={week.weekNumber}>
              {week.label}
            </MenuItem>
          ))}
        </Select>
        {errors.Mt_fecha_inicio && <span style={{ color: 'red' }}>{errors.Mt_fecha_inicio}</span>}
      </FormControl>

      <Box display='flex' justifyContent='space-between' mt={3}>
        <Button onClick={handleSubmit} variant='outlined' size='large' disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Crear'}
        </Button>
        <Button onClick={onClose} variant='outlined' color='error' size='large'>
          Cancelar
        </Button>
      </Box>
    </form>
  );
});

export default CreateMaterial;
