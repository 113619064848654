import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { UseAlert } from '../../../components/newAlert/NewAlert';
import { getDetalleMaterialGlobal, updateDetalleMaterialGlobal } from '../../../api/materialesGlobal';

const ViewMaterialDetalleByInstalador = React.memo(({ material, onClose }: any) => {
  const [detalleMaterial, setDetalleMaterial] = useState<any[]>([]);
  const { NewNotification } = UseAlert();

  useEffect(() => {
    if (material.MtGl_Id) {
      fetchDetalleMaterial();
    }
  }, [material.MtGl_Id]);

  const fetchDetalleMaterial = useCallback(async () => {
    const response = await getDetalleMaterialGlobal(material.MtGl_Id);
    if (response.isOk) {
      console.log(response.data);
      setDetalleMaterial(response.data);
    }
  }, [setDetalleMaterial, detalleMaterial]);

  const handleRowUpdate = useCallback(
    async (row: any) => {
      await updateDetalleMaterialGlobal(row.MtGlD_Id, { MtGlD_Inv_Inicial: row.MtGlD_Inv_Inicial, MtGlD_Ingresa: row.MtGlD_Ingresa }).then((response: any) => {
        if (response.isOk) {
          NewNotification('Producto actualizado correctamente', 'success');
          fetchDetalleMaterial();
        }
      });
    },
    [fetchDetalleMaterial, NewNotification]
  );

  const columns: GridColDef[] = [
    { field: 'Pr_Nombre', headerName: 'Producto', flex: 1, editable: false, sortable: false, filterable: false, hideable: false },
    { field: 'MtGlD_Inv_Inicial', headerName: 'Inicial', width: 80, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtGlD_Ingresa', headerName: 'Ingresa', width: 80, editable: true, sortable: false, filterable: false, hideable: false },
    { field: 'MtGlD_Descuento', headerName: 'Descuento', width: 90, editable: false, sortable: false, filterable: false, hideable: false },
    { field: 'MtGlD_Total', headerName: 'Total', width: 85, editable: false, sortable: false, filterable: false, hideable: false },
  ];

  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              getRowId={(row) => row.MtGlD_Id}
              rows={detalleMaterial}
              columns={columns}
              processRowUpdate={handleRowUpdate}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  textAlign: 'center',
                },
                '& .MuiDataGrid-columnGroupsHeader': {
                  display: 'flex',
                  justifyContent: 'center',
                },
                '& .MuiDataGrid-columnGroupHeaderCell': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
});

export default ViewMaterialDetalleByInstalador;
