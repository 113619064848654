import { UseApi } from '../utils/axios-pettitions';

export async function getMateriales() {
  try {
    const { runApi } = UseApi();
    const response = await runApi('material', 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener los productos',
    };
  }
}

export async function getMaterialById(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`material/${id}`, 'GET');
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el producto',
    };
  }
}

export async function createMaterial(data: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('material', 'POST', null, data);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el producto',
    };
  }
}

export async function updateMaterial(id: number, data: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`material/${id}`, 'PUT', null, data);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el producto',
    };
  }
}

export async function deleteMaterial(id: number) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`material`, 'DELETE', { id });
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {  
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al eliminar el producto',
    };
  }
}

export async function getDetalleMaterial(id: number) {
  try {
    const { runApi } = UseApi();    
    const response = await runApi(`material/registro`, 'GET',{id});
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al obtener el detalle del producto',
    };
  }
}

export async function createDetalleMaterial(data: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi('material/registro', 'POST', null, data);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al crear el producto',
    };
  }
}

export async function updateDetalleMaterial(id: number, data: any) {
  try {
    const { runApi } = UseApi();
    const response = await runApi(`material/registro`, 'PUT', {id}, data);
    if (!response.Success) {
      return {
        isOk: false,
        message: response.Message,
      };
    }
    return {
      isOk: true,
      data: response.Data,
    };
  } catch {
    return {
      isOk: false,
      message: 'Error al actualizar el producto',
    };
  }
}